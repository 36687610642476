@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Medium'), local('BloggerSans-Medium'), url('/assets/fonts/bloggersans/Bloggersansmedium.woff2') format('woff2'), url('/assets/fonts/bloggersans/Bloggersansmedium.woff') format('woff'), url('/assets/fonts/bloggersans/Bloggersansmedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Medium Italic'), local('BloggerSans-MediumItalic'), url('/assets/fonts/bloggersans/Bloggersansmediumitalic.woff2') format('woff2'), url('/assets/fonts/bloggersans/Bloggersansmediumitalic.woff') format('woff'), url('/assets/fonts/bloggersans/Bloggersansmediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Italic'), local('BloggerSans-Italic'), url('/assets/fonts/bloggersans/Bloggersansitalic.woff2') format('woff2'), url('/assets/fonts/bloggersans/Bloggersansitalic.woff') format('woff'), url('/assets/fonts/bloggersans/Bloggersansitalic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Light'), local('BloggerSans-Light'), url('/assets/fonts/bloggersans/Bloggersanslight.woff2') format('woff2'), url('/assets/fonts/bloggersans/Bloggersanslight.woff') format('woff'), url('/assets/fonts/bloggersans/Bloggersanslight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans'), local('BloggerSans'), url('/assets/fonts/bloggersans/Bloggersans.woff2') format('woff2'), url('/assets/fonts/bloggersans/Bloggersans.woff') format('woff'), url('/assets/fonts/bloggersans/Bloggersans.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Bold Italic'), local('BloggerSans-BoldItalic'), url('/assets/fonts/bloggersans/Bloggersansbolditalic.woff2') format('woff2'), url('/assets/fonts/bloggersans/Bloggersansbolditalic.woff') format('woff'), url('/assets/fonts/bloggersans/Bloggersansbolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Bold'), local('BloggerSans-Bold'), url('/assets/fonts/bloggersans/Bloggersansbold.woff2') format('woff2'), url('/assets/fonts/bloggersans/Bloggersansbold.woff') format('woff'), url('/assets/fonts/bloggersans/Bloggersansbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Blogger Sans';
    src: local('Blogger Sans Light Italic'), local('BloggerSans-LightItalic'), url('/assets/fonts/bloggersans/Bloggersanslightitalic.woff2') format('woff2'), url('/assets/fonts/bloggersans/Bloggersanslightitalic.woff') format('woff'), url('/assets/fonts/bloggersans/Bloggersanslightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:58 PM */

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BoldCondIt.eot');
	src: local('Myriad Pro Bold Condensed Italic'), local('MyriadPro-BoldCondIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldCondIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldCondIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldCondIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BlackIt.eot');
	src: local('Myriad Pro Black Italic'), local('MyriadPro-BlackIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-LightIt.eot');
	src: local('Myriad Pro Light Italic'), local('MyriadPro-LightIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BlackCond.eot');
	src: local('Myriad Pro Black Condensed'), local('MyriadPro-BlackCond'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackCond.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackCond.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackCond.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-Cond.eot');
	src: local('Myriad Pro Condensed'), local('MyriadPro-Cond'),
		url('/assets/fonts/MyriadPro/MyriadPro-Cond.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-Cond.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-Cond.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-Bold.eot');
	src: local('Myriad Pro Bold'), local('MyriadPro-Bold'),
		url('/assets/fonts/MyriadPro/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-Bold.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BoldIt.eot');
	src: local('Myriad Pro Bold Italic'), local('MyriadPro-BoldIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiCnIt.eot');
	src: local('Myriad Pro Bold SemiCondensed Italic'), local('MyriadPro-BoldSemiCnIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiCnIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiCnIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiCnIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-CondIt.eot');
	src: local('Myriad Pro Condensed Italic'), local('MyriadPro-CondIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-CondIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-CondIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-CondIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-LightCond.eot');
	src: local('Myriad Pro Light Condensed'), local('MyriadPro-LightCond'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightCond.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightCond.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightCond.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiCn.eot');
	src: local('Myriad Pro SemiCondensed'), local('MyriadPro-SemiCn'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiCn.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiCn.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiCn.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiCnIt.eot');
	src: local('Myriad Pro SemiCondensed Italic'), local('MyriadPro-SemiCnIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiCnIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiCnIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiCnIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BoldCond.eot');
	src: local('Myriad Pro Bold Condensed'), local('MyriadPro-BoldCond'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldCond.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldCond.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldCond.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-LightSemiExtIt.eot');
	src: local('Myriad Pro Light SemiExtended Italic'), local('MyriadPro-LightSemiExtIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiExtIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiExtIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiExtIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-Semibold.eot');
	src: local('Myriad Pro Semibold'), local('MyriadPro-Semibold'),
		url('/assets/fonts/MyriadPro/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-Semibold.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-Black.eot');
	src: local('Myriad Pro Black'), local('MyriadPro-Black'),
		url('/assets/fonts/MyriadPro/MyriadPro-Black.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-Black.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-Regular.eot');
	src: local('Myriad Pro Regular'), local('MyriadPro-Regular'),
		url('/assets/fonts/MyriadPro/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-Regular.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-LightSemiCn.eot');
	src: local('Myriad Pro Light SemiCondensed'), local('MyriadPro-LightSemiCn'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiCn.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiCn.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiCn.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BlackCondIt.eot');
	src: local('Myriad Pro Black Condensed Italic'), local('MyriadPro-BlackCondIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackCondIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackCondIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackCondIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-LightCondIt.eot');
	src: local('Myriad Pro Light Condensed Italic'), local('MyriadPro-LightCondIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightCondIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightCondIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightCondIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-LightSemiExt.eot');
	src: local('Myriad Pro Light SemiExtended'), local('MyriadPro-LightSemiExt'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiExt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiExt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiExt.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiCnIt.eot');
	src: local('Myriad Pro Black SemiCondensed Italic'), local('MyriadPro-BlackSemiCnIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiCnIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiCnIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiCnIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiboldCondIt.eot');
	src: local('Myriad Pro Semibold Condensed Italic'), local('MyriadPro-SemiboldCondIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldCondIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldCondIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldCondIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiboldCond.eot');
	src: local('Myriad Pro Semibold Condensed'), local('MyriadPro-SemiboldCond'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldCond.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldCond.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldCond.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiExtIt.eot');
	src: local('Myriad Pro Bold SemiExtended Italic'), local('MyriadPro-BoldSemiExtIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiExtIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiExtIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiExtIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiCn.eot');
	src: local('Myriad Pro Semibold SemiCondensed'), local('MyriadPro-SemiboldSemiCn'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiCn.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiCn.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiCn.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiExtIt.eot');
	src: local('Myriad Pro Black SemiExtended Italic'), local('MyriadPro-BlackSemiExtIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiExtIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiExtIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiExtIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiExtIt.eot');
	src: local('Myriad Pro Semibold SemiExtended Italic'), local('MyriadPro-SemiboldSemiExtIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiExtIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiExtIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiExtIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-LightSemiCnIt.eot');
	src: local('Myriad Pro Light SemiCondensed Italic'), local('MyriadPro-LightSemiCnIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiCnIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiCnIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-LightSemiCnIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiCn.eot');
	src: local('Myriad Pro Bold SemiCondensed'), local('MyriadPro-BoldSemiCn'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiCn.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiCn.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiCn.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiCnIt.eot');
	src: local('Myriad Pro Semibold SemiCondensed Italic'), local('MyriadPro-SemiboldSemiCnIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiCnIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiCnIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiCnIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiboldIt.eot');
	src: local('Myriad Pro Semibold Italic'), local('MyriadPro-SemiboldIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldIt.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-It.eot');
	src: local('Myriad Pro Italic'), local('MyriadPro-It'),
		url('/assets/fonts/MyriadPro/MyriadPro-It.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-It.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-It.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiExt.eot');
	src: local('Myriad Pro Bold SemiExtended'), local('MyriadPro-BoldSemiExt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiExt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiExt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BoldSemiExt.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-Light.eot');
	src: local('Myriad Pro Light'), local('MyriadPro-Light'),
		url('/assets/fonts/MyriadPro/MyriadPro-Light.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-Light.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiExt.eot');
	src: local('Myriad Pro Semibold SemiExtended'), local('MyriadPro-SemiboldSemiExt'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiExt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiExt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiboldSemiExt.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiExtIt.eot');
	src: local('Myriad Pro SemiExtended Italic'), local('MyriadPro-SemiExtIt'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiExtIt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiExtIt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiExtIt.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiCn.eot');
	src: local('Myriad Pro Black SemiCondensed'), local('MyriadPro-BlackSemiCn'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiCn.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiCn.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiCn.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiExt.eot');
	src: local('Myriad Pro Black SemiExtended'), local('MyriadPro-BlackSemiExt'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiExt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiExt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-BlackSemiExt.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Myriad Pro';
	src: url('/assets/fonts/MyriadPro/MyriadPro-SemiExt.eot');
	src: local('Myriad Pro SemiExtended'), local('MyriadPro-SemiExt'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiExt.eot?#iefix') format('embedded-opentype'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiExt.woff') format('woff'),
		url('/assets/fonts/MyriadPro/MyriadPro-SemiExt.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
